import Alpine from "alpinejs";
import htmx from "htmx.org";
import runClocks from './clock';

Alpine.start();

window.runClocks = runClocks;
window.onload = (event) => {
  window.runClocks();
};

htmx.on("htmx:responseError", function (evt) {
    window.location.href = `/layout/boost-error/?m=${encodeURIComponent(evt.detail.error)}`;
});
